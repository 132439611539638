import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ParsedUrlQuery } from 'querystring';
import {
  ApolloError,
  ApolloQueryResult,
  FetchMoreQueryOptions,
  NetworkStatus,
} from '@apollo/client';
import isEqual from 'lodash/isEqual';
import { useRouter } from 'next/router';
import {
  ContactHnwStatuses,
  InvestorSearchContactsAndShareholdingsAutocompleteQueryVariables,
  InvestorSearchContactsAndShareholdingsQuery,
  useInvestorSearchContactsAndShareholdingsQuery,
} from '@/apollo/generated';
import useBreakpoint from '@/components/pdf-viewer/use-breakpoint';
import {
  useSharedFilterContext,
  defaultContactsFilters,
  Order,
  Options,
} from '@/contexts/shared-filter-context';
import { Permissions, usePermission } from '@/hooks/use-permission';
import routes from '@/utils/routes';

interface InvestorsSearchGlobalContextProps {
  appliedContactsFiltersNum: number;
  appliedShareholdingsFiltersNum: number;
  contactsFilters: ContactsFilters;
  contactsOptions: Options;
  currentContactsPage: number;
  currentShareholdingsPage: number;
  data?: InvestorSearchContactsAndShareholdingsQuery;
  error?: ApolloError;
  fetchMore: (
    fetchMoreOptions: FetchMoreQueryOptions<InvestorSearchContactsAndShareholdingsAutocompleteQueryVariables>
  ) => Promise<ApolloQueryResult<InvestorSearchContactsAndShareholdingsQuery>>;
  hasAppliedContactsFilters: boolean;
  hasAppliedShareholdingsFilters: boolean;
  investorSearchPush: (newParams: ParsedUrlQuery) => Promise<boolean>;
  loading: boolean;
  networkStatus: NetworkStatus;
  orderContacts?: Order[];
  orderShareholdings?: Order[];
  rowsPerPage: number;
  searchPhrase: string;
  setAppliedContactsFiltersNum: React.Dispatch<React.SetStateAction<number>>;
  setAppliedShareholdingsFiltersNum: React.Dispatch<
    React.SetStateAction<number>
  >;
  setContactsFilters: Dispatch<SetStateAction<ContactsFilters>>;
  setRowsPerPage: React.Dispatch<React.SetStateAction<number>>;
  setSearchPhrase: Dispatch<SetStateAction<string>>;
  shareholdingsFilters: ShareholdingsFilters;
  shareholdingsOptions: Options;
  totals: { all: number; contacts: number; shareholdings: number };
}

export type ContactsFilterHNWType = string | ContactHnwStatuses;
export type ShareholdingsFilterTraitType =
  | 'none'
  | 'top_20'
  | 'top_50'
  | 'spp'
  | 'placement'
  | 'hnw_behaviour';

export type FilterLocations =
  | 'nsw'
  | 'vic'
  | 'qld'
  | 'wa'
  | 'wa'
  | 'sa'
  | 'tas'
  | 'nt'
  | 'act'
  | 'nz'
  | 'other';

export const FilterLocationOptions = [
  { id: 'nsw', label: 'NSW' },
  { id: 'vic', label: 'VIC' },
  { id: 'qld', label: 'QLD' },
  { id: 'wa', label: 'WA' },
  { id: 'sa', label: 'SA' },
  { id: 'tas', label: 'TAS' },
  { id: 'nt', label: 'NT' },
  { id: 'act', label: 'ACT' },
  { id: 'nz', label: 'NZ' },
  { id: 'other', label: 'Other' },
];

export const ContactSourceOptions = () =>
  [
    { id: 'hub_signup', label: 'Signed up to your hub' },
    { id: 'subscribe_form', label: 'Subscribed to email list' },
    { id: 'bulk_import', label: 'Uploaded from list' },
    { id: 'manual_creation', label: 'Manually created' },
  ].concat(
    usePermission(Permissions.registersShareholdingsAdmin)
      ? { id: 'registry_import', label: 'Imported from registry' }
      : []
  );

export type ContactSourceOptionsType =
  | 'registry_import'
  | 'hub_signup'
  | 'subscribe_form'
  | 'bulk_import'
  | 'manual_creation';

export type FilterContactSourceType = ContactSourceOptionsType[];

export type HasEmailOptionsType = 'none' | 'true' | 'suppressed' | 'false';

export const HasEmailOptions = () => [
  { id: null, label: 'No Filter' },
  { id: 'true', label: 'Has email address (valid)' },
  {
    id: 'suppressed',
    label: 'Has email address (undeliverable)',
    tooltip:
      'This email cannot be delivered to. Emails to this address may have failed to be delivered multiple times, or may have been marked as spam.',
  },
  { id: 'false', label: 'Doesn’t have email address' },
];

export type FilterHasEmailOptionsType = HasEmailOptionsType;

export type FilterLocationType = FilterLocations[];
export type FilterHasEntityType = 'none' | 'linked-only' | 'unlinked-only';
export type FilterShareholderType =
  | 'none'
  | 'nominated-shareholder'
  | 'nominated-shareholder-with-holding-info'
  | 'nominated-shareholder-without-holding-info'
  | 'shareholder'
  | 'past-shareholder'
  | 'not-shareholder';

export type FilterSubscriptionOptions =
  | 'none'
  | 'general'
  | 'announcement'
  | 'activity_update'
  | 'qa'
  | 'global-unsubscribed'
  | 'suppressed';

export type FilterSubscriptionType = FilterSubscriptionOptions[];
export type FilterNewShareholderType = 'none' | '30' | '60' | '90';
export type FilterInvestorLeadType =
  | 'none'
  | 'investor-lead'
  | 'not-investor-lead';

export type FilterTradingActivityType =
  | 'none'
  | 'churned'
  | 'downgrader'
  | 'new'
  | 'returning'
  | 'upgrader';

export type HubSignUpsDaysAgo = 'none' | '30';

export type ContactsFilters = {
  contactSource: FilterContactSourceType;
  hasEmail: FilterHasEmailOptionsType;
  hasInvestor: FilterHasEntityType;
  hnwStatus: ContactsFilterHNWType;
  hubSignUpsDaysAgo: HubSignUpsDaysAgo;
  leadStatus: FilterInvestorLeadType;
  location: FilterLocationType;
  maxMonths?: string;
  maxShares?: string;
  // '7' | '30' | '90' | '180' | 'YYYY-MM-DD,YYYY-MM-DD'
  minMonths?: string;
  minShares?: string;
  newShareholderStatus: FilterNewShareholderType;
  shareholderStatus: FilterShareholderType;
  staticListIds: string[];
  subscriptionStatus: FilterSubscriptionType;
  tags: string[];
  tradingActivity: FilterTradingActivityType;
  tradingActivityDateRange: string; // '7' | '30' | '90' | '180' | 'YYYY-MM-DD,YYYY-MM-DD's
  traits: ShareholdingsFilterTraitType;
};

export const defaultShareholdingsFilters: ShareholdingsFilters = {
  brokers: [],
  hasContact: 'none',
  location: [],
  maxMonths: undefined,
  maxShares: undefined,
  minMonths: undefined,
  minShares: undefined,
  traits: 'none',
};

export type ShareholdingsFilters = {
  brokers: string[];
  hasContact: FilterHasEntityType;
  location: FilterLocationType;
  maxMonths?: string;
  maxShares?: string;
  minMonths?: string;
  minShares?: string;
  traits: ShareholdingsFilterTraitType;
};

const InvestorsSearchGlobalContext =
  React.createContext<InvestorsSearchGlobalContextProps | null>(null);
export const useInvestorsSearchGlobal = () => {
  const context = React.useContext(InvestorsSearchGlobalContext);

  if (!context) {
    throw new Error(
      'Can only use investors search global context within investors search global provider'
    );
  }

  return context;
};

export const InvestorsSearchGlobalContextProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const breakPoint = useBreakpoint();
  const isMobile = breakPoint ? ['sm', 'md'].includes(breakPoint) : false;
  const router = useRouter();
  const { asPath, pathname, query } = router;
  const { id, marketListingKey } = query;
  const {
    brokers: queryBrokers,
    contact_location: queryContactLocation,
    contact_max_shares: queryContactMaxShares,
    contact_min_shares: queryContactMinShares,
    contacts_page: contactsPage,
    exclude_past: excludePastInvestors,
    has_contact: queryHasContact,
    has_email: queryHasEmail,
    has_investor: queryHasInvestor,
    hnw_status: queryHnwStatus,
    hub_sign_ups_days_ago: queryHubSignUpsDaysAgo,
    lead_status: queryHasInvestorLead,
    location: queryLocation,
    max_months: queryMaxMonths,
    max_shares: queryMaxShares,
    min_months: queryMinMonths,
    min_shares: queryMinShares,
    newholder_status: queryNewShareholderStatus,
    shareholder_status: queryShareholderStatus,
    shareholdings_order: shareholdingsOrder,
    shareholdings_order_by: shareholdingsOrderBy,
    shareholdings_page: shareholdingsPage,
    sources: queryContactSource,
    static_list_ids: queryStaticListIds,
    subscription_status: querySubscriptionStatus,
    tags: queryTags,
    tradingActivity: queryTradingActivity,
    tradingActivityDateRange: queryTradingActivityDateRange,
    traits: queryTraits,
  } = query;

  const includePast = `${excludePastInvestors as string}` !== 'true';

  const {
    contactsFilters,
    contactsOptions,
    orderContacts,
    searchPhrase,
    setContactsFilters,
    setSearchPhrase,
  } = useSharedFilterContext();

  const [hasAppliedContactsFilters, setHasAppliedContactsFilters] =
    useState<boolean>(false);
  const [appliedContactsFiltersNum, setAppliedContactsFiltersNum] =
    useState<number>(0);
  const [shareholdingsFilters, setShareholdingsFilters] =
    useState<ShareholdingsFilters>(defaultShareholdingsFilters);
  const [hasAppliedShareholdingsFilters, setHasAppliedShareholdingsFilters] =
    useState<boolean>(false);
  const [appliedShareholdingsFiltersNum, setAppliedShareholdingsFiltersNum] =
    useState<number>(0);

  useEffect(() => {
    if (contactsFilters) {
      setHasAppliedContactsFilters(
        !isEqual(contactsFilters, defaultContactsFilters)
      );
    }
  }, [contactsFilters]);

  useEffect(() => {
    let filters = shareholdingsFilters;

    const {
      brokers,
      hasContact,
      location,
      maxMonths,
      maxShares,
      minMonths,
      minShares,
      traits,
    } = filters;

    if (hasContact !== queryHasContact) {
      filters = {
        ...filters,
        hasContact: queryHasContact
          ? (queryHasContact as FilterHasEntityType)
          : defaultShareholdingsFilters.hasContact,
      };
    }
    if (location !== queryLocation) {
      filters = {
        ...filters,
        location: queryLocation
          ? (queryLocation as FilterLocationType)
          : defaultShareholdingsFilters.location,
      };
    }
    if (traits !== queryTraits) {
      filters = {
        ...filters,
        traits: queryTraits
          ? (queryTraits as ShareholdingsFilterTraitType)
          : defaultShareholdingsFilters.traits,
      };
    }
    if (minShares !== queryMinShares) {
      filters = {
        ...filters,
        minShares: queryMinShares
          ? (queryMinShares as string)
          : defaultShareholdingsFilters.minShares,
      };
    }
    if (maxShares !== queryMaxShares) {
      filters = {
        ...filters,
        maxShares: queryMaxShares
          ? (queryMaxShares as string)
          : defaultShareholdingsFilters.maxShares,
      };
    }
    if (minMonths !== queryMinMonths) {
      filters = {
        ...filters,
        minMonths: queryMinMonths
          ? (queryMinMonths as string)
          : defaultShareholdingsFilters.minMonths,
      };
    }
    if (maxMonths !== queryMaxMonths) {
      filters = {
        ...filters,
        maxMonths: queryMaxMonths
          ? (queryMaxMonths as string)
          : defaultShareholdingsFilters.maxMonths,
      };
    }
    if (brokers !== queryBrokers) {
      filters = {
        ...filters,
        brokers: queryBrokers
          ? (queryBrokers as string[])
          : defaultShareholdingsFilters.brokers,
      };
    }

    setShareholdingsFilters(filters);
    setHasAppliedShareholdingsFilters(
      !isEqual(filters, defaultShareholdingsFilters)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    queryHasContact,
    queryMinShares,
    queryMaxShares,
    queryTraits,
    queryMinMonths,
    queryMaxMonths,
    queryBrokers,
    queryLocation,
  ]);

  const investorSearchPush = (newParams: ParsedUrlQuery) => {
    // Reset pagination
    delete query['contacts_page'];
    delete query['shareholdings_page'];

    return router.push(
      {
        pathname: pathname,
        query: { ...query, ...newParams },
      },
      undefined,
      { shallow: true }
    );
  };

  const maybeUpdateQueryParam = (
    newQueryParam: string | string[] | undefined,
    curQueryParam: string | string[] | undefined,
    fallback: string | string[] | undefined
  ) => {
    if (newQueryParam !== curQueryParam) {
      return newQueryParam || fallback;
    }
    return curQueryParam;
  };

  useEffect(() => {
    const filters = contactsFilters;
    const updatedFilters = {
      ...filters,
      hasInvestor:
        queryHasInvestor ||
        filters.hasInvestor ||
        defaultContactsFilters.hasInvestor,
      hnwStatus:
        queryHnwStatus || filters.hnwStatus || defaultContactsFilters.hnwStatus,
      hubSignUpsDaysAgo:
        queryHubSignUpsDaysAgo ||
        filters.hubSignUpsDaysAgo ||
        defaultContactsFilters.hubSignUpsDaysAgo,
      leadStatus:
        queryHasInvestorLead ||
        filters.leadStatus ||
        defaultContactsFilters.leadStatus,
      maxMonths:
        queryMaxMonths || filters.maxMonths || defaultContactsFilters.maxMonths,
      minMonths:
        queryMinMonths || filters.minMonths || defaultContactsFilters.minMonths,
      newShareholderStatus:
        queryNewShareholderStatus ||
        filters.newShareholderStatus ||
        defaultContactsFilters.newShareholderStatus,
      shareholderStatus:
        queryShareholderStatus ||
        filters.shareholderStatus ||
        defaultContactsFilters.shareholderStatus,
      subscriptionStatus:
        querySubscriptionStatus ||
        filters.subscriptionStatus ||
        defaultContactsFilters.subscriptionStatus,
      tradingActivity:
        queryTradingActivity ||
        filters.tradingActivity ||
        defaultContactsFilters.tradingActivity,
      tradingActivityDateRange:
        queryTradingActivityDateRange ||
        filters.tradingActivityDateRange ||
        defaultContactsFilters.tradingActivityDateRange,
      traits: queryTraits || filters.traits || defaultContactsFilters.traits,
    };

    updatedFilters.location = maybeUpdateQueryParam(
      queryContactLocation,
      filters.location,
      defaultContactsFilters.location
    ) as FilterLocationType;

    updatedFilters.contactSource = maybeUpdateQueryParam(
      queryContactSource,
      filters.contactSource,
      defaultContactsFilters.contactSource
    ) as FilterContactSourceType;

    updatedFilters.hasEmail = maybeUpdateQueryParam(
      queryHasEmail,
      filters.hasEmail,
      defaultContactsFilters.hasEmail
    ) as FilterHasEmailOptionsType;

    updatedFilters.maxShares = maybeUpdateQueryParam(
      queryContactMaxShares,
      filters.maxShares,
      defaultContactsFilters.maxShares
    ) as string;

    updatedFilters.minShares = maybeUpdateQueryParam(
      queryContactMinShares,
      filters.minShares,
      defaultContactsFilters.minShares
    ) as string;

    updatedFilters.subscriptionStatus = maybeUpdateQueryParam(
      querySubscriptionStatus,
      filters.subscriptionStatus,
      defaultContactsFilters.subscriptionStatus
    ) as FilterSubscriptionType;

    updatedFilters.tags = maybeUpdateQueryParam(
      queryTags,
      filters.tags,
      defaultContactsFilters.tags
    ) as string[];

    updatedFilters.staticListIds = maybeUpdateQueryParam(
      queryStaticListIds,
      filters.staticListIds,
      defaultContactsFilters.staticListIds
    ) as string[];

    updatedFilters.minMonths = maybeUpdateQueryParam(
      queryMinMonths,
      filters.minMonths,
      defaultContactsFilters.minMonths
    ) as string;

    updatedFilters.maxMonths = maybeUpdateQueryParam(
      queryMaxMonths,
      filters.maxMonths,
      defaultContactsFilters.maxMonths
    ) as string;

    updatedFilters.traits = maybeUpdateQueryParam(
      queryTraits,
      filters.traits,
      defaultContactsFilters.traits
    ) as ShareholdingsFilterTraitType;

    if (JSON.stringify(updatedFilters) !== JSON.stringify(contactsFilters)) {
      setContactsFilters(updatedFilters as ContactsFilters);
    }
  }, [
    queryTraits,
    queryContactSource,
    queryHasEmail,
    queryContactLocation,
    queryContactMaxShares,
    queryContactMinShares,
    queryHasInvestor,
    queryHnwStatus,
    queryHubSignUpsDaysAgo,
    queryHasInvestorLead,
    queryNewShareholderStatus,
    queryShareholderStatus,
    queryMinMonths,
    queryMaxMonths,
    querySubscriptionStatus,
    queryTags,
    queryStaticListIds,
    queryTradingActivity,
    queryTradingActivityDateRange,
    contactsFilters,
    setContactsFilters,
  ]);

  const currentContactsPage =
    contactsPage && !isNaN(Number(contactsPage)) ? Number(contactsPage) : 1;
  const currentShareholdingsPage =
    shareholdingsPage && !isNaN(Number(shareholdingsPage))
      ? Number(shareholdingsPage)
      : 1;
  const [totals, setTotals] = useState<{
    all: number;
    contacts: number;
    shareholdings: number;
  }>({ all: 0, contacts: 0, shareholdings: 0 });
  const ROWS_PER_PAGE = 10;

  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE);

  const orderShareholdings = useMemo<Order[]>(() => {
    if (shareholdingsOrderBy && shareholdingsOrder) {
      return [
        {
          key: shareholdingsOrderBy as string,
          value: shareholdingsOrder as string,
        },
      ];
    }

    return [];
  }, [shareholdingsOrderBy, shareholdingsOrder]);

  const shareholdingsOptions = {
    // Traits must be first, as we rank over the whole data set first
    // Otherwise if filtering by top 50 or top 20 it will only get the
    // filtered top 20 / 50
    filters: [
      {
        key: 'traits',
        value: shareholdingsFilters.traits,
      },
      {
        key: 'search',
        value: searchPhrase,
      },
      {
        key: 'include_past',
        value: `${includePast}`,
      },
      {
        key: 'has_contact',
        value: shareholdingsFilters.hasContact,
      },
      {
        key: 'min_share_count',
        value: `${shareholdingsFilters.minShares}`,
      },
      {
        key: 'max_share_count',
        value: `${shareholdingsFilters.maxShares}`,
      },
      {
        key: 'min_months_held',
        value: `${shareholdingsFilters.minMonths}`,
      },
      {
        key: 'max_months_held',
        value: `${shareholdingsFilters.maxMonths}`,
      },
      {
        key: 'location',
        value: Array.isArray(shareholdingsFilters.location)
          ? shareholdingsFilters.location.join(',')
          : shareholdingsFilters.location,
      },
      {
        key: 'broker_short_names',
        value: Array.isArray(shareholdingsFilters.brokers)
          ? shareholdingsFilters.brokers.join(',')
          : shareholdingsFilters.brokers,
      },
    ],
    orders: orderShareholdings,
  };

  const getPageCursor = (page: string | string[] | undefined) => {
    const newPage = page && !isNaN(Number(page)) ? Number(page) : 1;
    return Buffer.from(
      `arrayconnection:${rowsPerPage * (newPage - 1) - 1}`
    ).toString('base64');
  };

  const skipInvestorSearch =
    asPath.startsWith(
      routes.engagement.audiences.dynamicLists.list.href(
        marketListingKey as string,
        id as string
      )
    ) ||
    asPath.startsWith(
      routes.engagement.audiences.dynamicLists.list.createList.href(
        marketListingKey as string
      )
    );

  const { data, error, fetchMore, loading, networkStatus } =
    useInvestorSearchContactsAndShareholdingsQuery({
      fetchPolicy: isMobile ? 'cache-and-network' : 'no-cache',
      nextFetchPolicy: isMobile ? 'cache-first' : 'no-cache',
      skip: skipInvestorSearch,
      variables: {
        contactsAfter: getPageCursor(contactsPage),
        contactsFirst: rowsPerPage,
        contactsOptions,
        shareholdingsAfter: getPageCursor(shareholdingsPage),
        shareholdingsFirst: rowsPerPage,
        shareholdingsOptions,
      },
    });

  useEffect(() => {
    if (data?.contacts && data?.shareholdings) {
      const newTotals = {
        all: data.contacts.total + data.shareholdings.total,
        contacts: data.contacts.total,
        shareholdings: data.shareholdings.total,
      };
      if (
        newTotals.contacts != totals.contacts ||
        newTotals.shareholdings != totals.shareholdings
      ) {
        setTotals(newTotals);
      }
    }
  }, [data, totals]);

  const getAppliedContactsFiltersNum = (
    appliedContactsFilters: ContactsFilters
  ) => {
    let totalAppliedContactsFilters = 0;

    const {
      contactSource,
      hasEmail,
      hasInvestor,
      hnwStatus,
      hubSignUpsDaysAgo,
      leadStatus,
      location,
      maxMonths,
      maxShares,
      minMonths,
      minShares,
      newShareholderStatus,
      shareholderStatus,
      staticListIds,
      subscriptionStatus,
      tags,
      tradingActivity,
      traits,
    } = appliedContactsFilters;

    if (hasInvestor !== 'none') {
      totalAppliedContactsFilters = totalAppliedContactsFilters + 1;
    }

    if (hubSignUpsDaysAgo !== 'none') {
      totalAppliedContactsFilters = totalAppliedContactsFilters + 1;
    }

    if (leadStatus !== 'none') {
      totalAppliedContactsFilters = totalAppliedContactsFilters + 1;
    }

    if (shareholderStatus !== 'none') {
      totalAppliedContactsFilters = totalAppliedContactsFilters + 1;
    }

    const numOfFilteredSubscriptions = Array.isArray(subscriptionStatus)
      ? subscriptionStatus.length
      : 1;

    if (newShareholderStatus !== 'none') {
      totalAppliedContactsFilters = totalAppliedContactsFilters + 1;
    }

    if (hnwStatus !== 'none') {
      totalAppliedContactsFilters = totalAppliedContactsFilters + 1;
    }

    if (traits !== 'none') {
      totalAppliedContactsFilters = totalAppliedContactsFilters + 1;
    }

    if (tradingActivity !== 'none') {
      totalAppliedContactsFilters = totalAppliedContactsFilters + 1;
    }

    if (minShares) {
      totalAppliedContactsFilters = totalAppliedContactsFilters + 1;
    }

    if (maxShares) {
      totalAppliedContactsFilters = totalAppliedContactsFilters + 1;
    }

    const numOfFilteredTags = Array.isArray(tags) ? tags.length : 1;
    const numOfStaticListIds = Array.isArray(staticListIds)
      ? staticListIds.length
      : 1;

    const numOfFilteredLocations = Array.isArray(location)
      ? location.length
      : 1;

    const numOfFilteredContactSource = Array.isArray(contactSource)
      ? contactSource.length
      : 1;

    const numOfFilteredHasEmail = Array.isArray(hasEmail) ? hasEmail.length : 1;

    if (minMonths)
      totalAppliedContactsFilters = totalAppliedContactsFilters + 1;
    if (maxMonths)
      totalAppliedContactsFilters = totalAppliedContactsFilters + 1;

    return (
      totalAppliedContactsFilters +
      numOfStaticListIds +
      numOfFilteredTags +
      numOfFilteredLocations +
      numOfFilteredContactSource +
      numOfFilteredSubscriptions +
      numOfFilteredHasEmail
    );
  };

  useEffect(() => {
    setAppliedContactsFiltersNum(getAppliedContactsFiltersNum(contactsFilters));
  }, [contactsFilters]);

  const getAppliedShareholdingsFiltersNum = (
    appliedShareholdingsFilters: ShareholdingsFilters
  ) => {
    let totalAppliedContactsFilters = 0;

    const {
      brokers,
      hasContact,
      location,
      maxMonths,
      maxShares,
      minMonths,
      minShares,
      traits,
    } = appliedShareholdingsFilters;

    if (hasContact !== 'none') {
      totalAppliedContactsFilters = totalAppliedContactsFilters + 1;
    }

    if (traits !== 'none') {
      totalAppliedContactsFilters = totalAppliedContactsFilters + 1;
    }

    if (minShares) {
      totalAppliedContactsFilters = totalAppliedContactsFilters + 1;
    }

    if (maxShares) {
      totalAppliedContactsFilters = totalAppliedContactsFilters + 1;
    }

    if (minMonths) {
      totalAppliedContactsFilters = totalAppliedContactsFilters + 1;
    }

    if (maxMonths) {
      totalAppliedContactsFilters = totalAppliedContactsFilters + 1;
    }

    const numOfFilteredBrokers =
      (Array.isArray(brokers) && brokers.length > 0) ||
      (typeof brokers == 'string' && brokers !== '')
        ? 1
        : 0;
    const numOfFilteredLocations =
      (Array.isArray(location) && location.length > 0) ||
      (typeof location == 'string' && location !== '')
        ? 1
        : 0;

    return (
      totalAppliedContactsFilters +
      numOfFilteredBrokers +
      numOfFilteredLocations
    );
  };

  useEffect(() => {
    setAppliedShareholdingsFiltersNum(
      getAppliedShareholdingsFiltersNum(shareholdingsFilters)
    );
  }, [shareholdingsFilters]);

  return (
    <InvestorsSearchGlobalContext.Provider
      value={{
        appliedContactsFiltersNum,
        appliedShareholdingsFiltersNum,
        contactsFilters,
        contactsOptions,
        currentContactsPage,
        currentShareholdingsPage,
        data,
        error,
        fetchMore,
        hasAppliedContactsFilters,
        hasAppliedShareholdingsFilters,
        investorSearchPush,
        loading,
        networkStatus,
        orderContacts,
        orderShareholdings,
        rowsPerPage,
        searchPhrase,
        setAppliedContactsFiltersNum,
        setAppliedShareholdingsFiltersNum,
        setContactsFilters,
        setRowsPerPage,
        setSearchPhrase,
        shareholdingsFilters,
        shareholdingsOptions,
        totals,
      }}
    >
      {children}
    </InvestorsSearchGlobalContext.Provider>
  );
};
