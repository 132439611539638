/**
 * @module SharedFilterContext
 *
 * @description The `SharedFilterContext` is responsible for managing the state of contact filters used in
 * filtering and displaying investor data across various components, such as search and contact list editors.
 * It ensures that filters are consistent across components.
 *
 * Currently used in:
 * - `InvestorSearch` context
 * - `CreateOrEditDynamicList` context
 * - `CreateOrEditStaticList` context
 *
 *
 * The context provides:
 * - `contactsFilters`: The current applied filters for contacts.
 * - `contactsOptions`: The current applied filters for contacts in a format suitable for Apollo queries.
 * - `defaultContactsFilters`: The default state of the filters before any modifications.
 * - `filtersInitialized`: Tracks whether filters have been initialized to avoid redundant updates.
 * - `onClickClearAllContactFilters`: Resets all filters to their default state.
 * - `setContactsFilters`: A setter to manually update the filter state.
 * - `searchPhrase`: The current search phrase used to filter contacts via query param.
 *
 * ## Usage:
 * Components that rely on contact filters should wrap their content with `SharedFilterContextProvider`
 * and access the context using the `useSharedFilterContext` hook.
 *
 * @example
 * ```js
 * const { contactsFilters, setContactsFilters, onClickClearAllContactFilters } = useSharedFilterContext();
 * ```
 */

import {
  createContext,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
  useMemo,
  useEffect,
} from 'react';
import { useRouter } from 'next/router';
import { ContactsFilters } from '@/components/investors/search/global/context';

export const defaultContactsFilters: ContactsFilters = {
  contactSource: [],
  hasEmail: 'none',
  hasInvestor: 'none',
  hnwStatus: 'none',
  hubSignUpsDaysAgo: 'none',
  leadStatus: 'none',
  location: [],
  maxMonths: undefined,
  maxShares: undefined,
  minMonths: undefined,
  minShares: undefined,
  newShareholderStatus: 'none',
  shareholderStatus: 'none',
  staticListIds: [],
  subscriptionStatus: [],
  tags: [],
  tradingActivity: 'none',
  tradingActivityDateRange: '30',
  traits: 'none',
};

export interface Order {
  key: string;
  value: string;
}
export type Options = {
  filters: {
    key: string;
    value: string;
  }[];
  orders: {
    key: string;
    value: string;
  }[];
};

interface SharedFilterContextProps {
  contactsFilters: ContactsFilters;
  contactsOptions: Options;
  defaultContactsFilters: ContactsFilters;
  onClickClearAllContactFilters: () => void;
  orderContacts: Order[];
  searchPhrase: string;
  setContactIds: Dispatch<SetStateAction<string[]>>;
  setContactsFilters: Dispatch<SetStateAction<ContactsFilters>>;
  setSearchPhrase: Dispatch<SetStateAction<string>>;
}

const SharedFilterContext = createContext<SharedFilterContextProps | null>(
  null
);

export const useSharedFilterContext = () => {
  const context = useContext(SharedFilterContext);
  if (!context) {
    throw new Error(
      'useSharedFilterContext must be used within a SharedFilterContextProvider'
    );
  }
  return context;
};

export const SharedFilterContextProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const { query } = useRouter();
  const {
    contacts_order: contactsOrder,
    contacts_order_by: contactsOrderBy,
    search,
  } = query;

  const [contactsFilters, setContactsFilters] = useState<ContactsFilters>(
    defaultContactsFilters
  );
  const [searchPhrase, setSearchPhrase] = useState((search as string) ?? '');
  const [contactIds, setContactIds] = useState<string[]>([]);

  const onClickClearAllContactFilters = () => {
    setContactsFilters(defaultContactsFilters);
  };

  // Handle search from query
  useEffect(() => {
    setSearchPhrase((search as string) ?? '');
  }, [search, setSearchPhrase]);

  const orderContacts = useMemo<Order[]>(() => {
    if (contactsOrderBy && contactsOrder) {
      return [
        {
          key: contactsOrderBy as string,
          value: contactsOrder as string,
        },
      ];
    }

    return [{ key: 'inserted_at', value: 'desc' }];
  }, [contactsOrderBy, contactsOrder]);

  const contactsOptions = useMemo(() => {
    return {
      filters: [
        {
          key: 'search_with_investor_users',
          value: searchPhrase,
        },
        {
          key: 'tags',
          value: Array.isArray(contactsFilters.tags)
            ? contactsFilters.tags.join(',')
            : contactsFilters.tags,
        },
        {
          key: 'static_list_ids',
          value: Array.isArray(contactsFilters.staticListIds)
            ? contactsFilters.staticListIds.join(',')
            : contactsFilters.staticListIds,
        },
        {
          key: 'has_investor_hub_user',
          value: contactsFilters.hasInvestor,
        },
        {
          key: 'lead_status',
          value: contactsFilters.leadStatus,
        },
        {
          key: 'shareholder_status',
          value: contactsFilters.shareholderStatus,
        },
        {
          key: 'subscription_status',
          value: Array.isArray(contactsFilters.subscriptionStatus)
            ? contactsFilters.subscriptionStatus.join(',')
            : contactsFilters.subscriptionStatus,
        },
        {
          key: 'newholder_status',
          value: contactsFilters.newShareholderStatus,
        },
        {
          key: 'hnw_status',
          value: contactsFilters.hnwStatus.toLowerCase(),
        },
        {
          key: 'location',
          value: Array.isArray(contactsFilters.location)
            ? contactsFilters.location.join(',')
            : contactsFilters.location,
        },
        {
          key: 'sources',
          value: Array.isArray(contactsFilters.contactSource)
            ? contactsFilters.contactSource.join(',')
            : contactsFilters.contactSource,
        },
        {
          key: 'has_email',
          value: contactsFilters.hasEmail,
        },
        {
          key: 'hub_sign_ups_days_ago',
          value: contactsFilters.hubSignUpsDaysAgo,
        },
        {
          key: 'trading_activity',
          value:
            contactsFilters.tradingActivity === 'none'
              ? 'none'
              : `${contactsFilters.tradingActivity},${contactsFilters.tradingActivityDateRange}`,
        },
        {
          key: 'traits',
          value: contactsFilters.traits,
        },
        ...(contactsFilters.minShares
          ? [
              {
                key: 'min_share_count',
                value: `${contactsFilters.minShares}`,
              },
            ]
          : []),
        ...(contactsFilters.maxShares
          ? [
              {
                key: 'max_share_count',
                value: `${contactsFilters.maxShares}`,
              },
            ]
          : []),
        ...(contactsFilters.minMonths
          ? [
              {
                key: 'min_months',
                value: `${contactsFilters.minMonths}`,
              },
            ]
          : []),
        ...(contactsFilters.maxMonths
          ? [
              {
                key: 'max_months',
                value: `${contactsFilters.maxMonths}`,
              },
            ]
          : []),
        ...(contactIds.length > 0
          ? [
              {
                key: 'include_ids',
                value: contactIds.join(','),
              },
            ]
          : []),
      ],
      orders: orderContacts,
    };
  }, [contactsFilters, orderContacts, searchPhrase, contactIds]);

  return (
    <SharedFilterContext.Provider
      value={{
        contactsFilters,
        contactsOptions,
        defaultContactsFilters,
        onClickClearAllContactFilters,
        orderContacts,
        searchPhrase,
        setContactIds,
        setContactsFilters,
        setSearchPhrase,
      }}
    >
      {children}
    </SharedFilterContext.Provider>
  );
};
