// DS V2
import { ReactNode } from 'react';
import { Typography, Button } from '@ds';

interface Props {
  action?: JSX.Element;
  clearFilters?: () => void;
  columnCount?: number;
  customCTA?: ReactNode;
  customClearText?: string;
  message?: string;
  secondaryMessage?: string | false;
  setSearchPhrase?: () => void;
}

const TableEmpty: React.ComponentType<Props> = ({
  action,
  clearFilters,
  columnCount = 1,
  customCTA,
  customClearText = 'Clear filters',
  message = 'None found',
  secondaryMessage = 'Your search or filter did not match any results.',
  setSearchPhrase,
}) => {
  return (
    <tr className="table-row">
      <td className="table-cell p-2" colSpan={columnCount}>
        <div className="mx-auto flex min-h-[192px] max-w-[500px] flex-col items-center justify-center pb-10">
          <div className="mb-4">
            <svg
              fill="none"
              height="180"
              viewBox="0 0 180 180"
              width="180"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                clipRule="evenodd"
                d="M154.688 40.2947H33.75C29.0897 40.2947 25.3125 44.0719 25.3125 48.7322V65.6072H154.688V40.2947Z"
                fill="url(#paint0_linear_2654_6959)"
                fillRule="evenodd"
              />
              <path
                clipRule="evenodd"
                d="M154.687 40.4297C154.687 35.7694 150.91 31.9922 146.25 31.9922C134.356 31.9922 112.913 31.9922 102.153 31.9922C98.1169 31.9922 94.3875 34.155 92.385 37.6594C89.0634 43.4756 84.375 51.6797 84.375 51.6797H154.687V40.4297Z"
                fill="url(#paint1_linear_2654_6959)"
                fillRule="evenodd"
              />
              <path
                clipRule="evenodd"
                d="M164.159 64.4371C164.606 61.214 163.636 57.9543 161.501 55.5018C159.364 53.0465 156.27 51.6375 153.016 51.6375C124.34 51.6375 55.6586 51.6375 26.9824 51.6375C23.7283 51.6375 20.6346 53.0465 18.4971 55.5018C16.3624 57.9543 15.3921 61.214 15.8392 64.4371C18.6911 84.9684 24.2261 124.816 26.7771 143.187C27.5505 148.747 32.3036 152.887 37.9202 152.887H142.078C147.695 152.887 152.448 148.747 153.221 143.187C155.772 124.816 161.307 84.9684 164.159 64.4371Z"
                fill="url(#paint2_linear_2654_6959)"
                fillRule="evenodd"
              />
              <path
                clipRule="evenodd"
                d="M16.7842 71.2434C16.9952 68.9006 17.9346 66.6618 19.5067 64.8562C21.6414 62.4037 24.7352 60.9946 27.9892 60.9946H152.009C155.263 60.9946 158.357 62.4037 160.492 64.8562C162.064 66.6618 163.003 68.9006 163.214 71.2434L164.159 64.4371C164.606 61.214 163.636 57.9543 161.501 55.5018C159.364 53.0465 156.27 51.6375 153.016 51.6375C124.34 51.6375 55.6586 51.6375 26.9824 51.6375C23.7283 51.6375 20.6346 53.0465 18.4971 55.5018C16.3624 57.9543 15.3921 61.214 15.8392 64.4371L16.7842 71.2434Z"
                fill="url(#paint3_linear_2654_6959)"
                fillRule="evenodd"
              />
              <path
                clipRule="evenodd"
                d="M77.3457 124.836C77.3457 121.731 74.8285 119.211 71.7207 119.211C65.4798 119.211 55.4645 119.211 49.2207 119.211C46.1157 119.211 43.5957 121.731 43.5957 124.836V130.461C43.5957 133.568 46.1157 136.086 49.2207 136.086H71.7207C74.8285 136.086 77.3457 133.568 77.3457 130.461C77.3457 128.652 77.3457 126.647 77.3457 124.836Z"
                fill="url(#paint4_linear_2654_6959)"
                fillRule="evenodd"
              />
              <path
                clipRule="evenodd"
                d="M119.531 72C103.233 72 90 85.2328 90 101.531C90 117.83 103.233 131.062 119.531 131.062C135.83 131.062 149.062 117.83 149.062 101.531C149.062 85.2328 135.83 72 119.531 72ZM119.531 80.4375C131.172 80.4375 140.625 89.8903 140.625 101.531C140.625 113.172 131.172 122.625 119.531 122.625C107.89 122.625 98.4375 113.172 98.4375 101.531C98.4375 89.8903 107.89 80.4375 119.531 80.4375Z"
                fill="url(#paint5_linear_2654_6959)"
                fillRule="evenodd"
              />
              <path
                clipRule="evenodd"
                d="M134.912 121.925L161.147 148.16C162.795 149.808 165.467 149.808 167.115 148.16C168.76 146.514 168.76 143.84 167.115 142.194L140.88 115.959C139.232 114.314 136.56 114.314 134.912 115.959C133.266 117.605 133.266 120.279 134.912 121.925Z"
                fill="url(#paint6_linear_2654_6959)"
                fillRule="evenodd"
              />
              <defs>
                <linearGradient
                  gradientUnits="userSpaceOnUse"
                  id="paint0_linear_2654_6959"
                  x1="34.8909"
                  x2="43.4026"
                  y1="43.6352"
                  y2="76.9252"
                >
                  <stop stopColor="#F5FFFC" />
                  <stop offset="1" stopColor="#B7D5D0" />
                </linearGradient>
                <linearGradient
                  gradientUnits="userSpaceOnUse"
                  id="paint1_linear_2654_6959"
                  x1="89.5806"
                  x2="98.4824"
                  y1="34.5904"
                  y2="58.918"
                >
                  <stop stopColor="#F5FFFC" />
                  <stop offset="1" stopColor="#B7D5D0" />
                </linearGradient>
                <linearGradient
                  gradientUnits="userSpaceOnUse"
                  id="paint2_linear_2654_6959"
                  x1="26.7292"
                  x2="97.1903"
                  y1="64.9996"
                  y2="144.097"
                >
                  <stop stopColor="#F5FFFC" />
                  <stop offset="1" stopColor="#B7D5D0" />
                </linearGradient>
                <linearGradient
                  gradientUnits="userSpaceOnUse"
                  id="paint3_linear_2654_6959"
                  x1="26.7292"
                  x2="31.3309"
                  y1="54.2249"
                  y2="80.9017"
                >
                  <stop stopColor="#F5FFFC" />
                  <stop offset="1" stopColor="#B7D5D0" />
                </linearGradient>
                <linearGradient
                  gradientUnits="userSpaceOnUse"
                  id="paint4_linear_2654_6959"
                  x1="46.0944"
                  x2="56.9213"
                  y1="121.438"
                  y2="138.007"
                >
                  <stop stopColor="#72A49D" />
                  <stop offset="1" stopColor="#07685A" />
                </linearGradient>
                <linearGradient
                  gradientUnits="userSpaceOnUse"
                  id="paint5_linear_2654_6959"
                  x1="94.3727"
                  x2="134.311"
                  y1="79.7946"
                  y2="110.356"
                >
                  <stop stopColor="#72A49D" />
                  <stop offset="1" stopColor="#07685A" />
                </linearGradient>
                <linearGradient
                  gradientUnits="userSpaceOnUse"
                  id="paint6_linear_2654_6959"
                  x1="136.245"
                  x2="159.689"
                  y1="119.301"
                  y2="137.241"
                >
                  <stop stopColor="#72A49D" />
                  <stop offset="1" stopColor="#07685A" />
                </linearGradient>
              </defs>
            </svg>
          </div>
          <Typography className="mb-2 text-gray-900" variant="text-heading-sm">
            {message}
          </Typography>
          {secondaryMessage ? (
            <Typography
              className="whitespace-pre-line text-center text-gray-500"
              variant="text-body-sm"
            >
              {secondaryMessage}
            </Typography>
          ) : null}
          {setSearchPhrase && (
            <Button
              className="mt-4"
              size="sm"
              variant="secondary-gray"
              onClick={setSearchPhrase}
            >
              Clear search
            </Button>
          )}
          {customCTA && customCTA}
          {clearFilters && (
            <Button
              className="mt-4"
              size="sm"
              variant="secondary-gray"
              onClick={clearFilters}
            >
              {customClearText}
            </Button>
          )}
          {action}
        </div>
      </td>
    </tr>
  );
};

export default TableEmpty;
